var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lobby-page", attrs: { "data-v-step": "0" } },
    [
      _c("fade-transition", [
        _vm.isDesktop
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.bannersrc && _vm.bannerLoaded,
                    expression: "bannersrc && bannerLoaded",
                  },
                ],
                staticClass: "space-cover-image",
              },
              [
                _c("img", {
                  attrs: { src: _vm.bannersrc, alt: "space cover image" },
                  on: {
                    load: function ($event) {
                      _vm.bannerLoaded = true
                    },
                  },
                }),
              ]
            )
          : _vm._e(),
      ]),
      _vm.getAreaStopSpacesBackup ? _c("rooms") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }