var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "room",
      class: [
        "room--" + _vm.data.spaceType,
        { "room--joined": _vm.inRoom },
        { "room--visible": _vm.visible },
        { "room--viewing": _vm.viewingRoom },
        { "room--in-search": _vm.inSearch },
        { "room--has-bg": _vm.backgroundImage },
      ],
      style: _vm.style,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick.apply(null, arguments)
        },
      },
    },
    [
      _vm.isCreate
        ? _c("img", { attrs: { src: require("@/assets/create-room.svg") } })
        : _c("div", { staticClass: "room-content" }, [
            _vm.isHub
              ? _c("div", { staticClass: "room-content-hub" }, [
                  _vm.logo
                    ? _c("div", {
                        staticClass: "room-content-hub__logo",
                        style: `background-image: url(${_vm.logo})`,
                      })
                    : _c("div", { staticClass: "room-content-hub__intials" }, [
                        _vm._v(_vm._s(_vm.areaInitials)),
                      ]),
                ])
              : _vm.backgroundImage
              ? _c("div", { staticClass: "room-content__bg" }, [
                  _c("img", {
                    staticClass: "room-content__bg-image",
                    attrs: { "aria-hidden": "true", src: _vm.backgroundImage },
                  }),
                ])
              : _vm._e(),
            !_vm.hideName
              ? _c("div", { staticClass: "room-content__name" }, [
                  _vm._v(_vm._s(_vm.name)),
                ])
              : _vm._e(),
            _vm.viewingRoom && !_vm.isHub
              ? _c("div", { staticClass: "room-content__button" }, [
                  _c("img", {
                    attrs: {
                      src: require(`@/assets/${_vm.buttonContent.icon}.svg`),
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.buttonContent.text) + " "),
                ])
              : _vm._e(),
            _c("div", { staticClass: "room-content__online" }, [
              _vm._v(_vm._s(_vm.data.online)),
            ]),
          ]),
      _c(
        "transition-group",
        _vm._l(_vm.usersToShow, function ({ userId, status }, index) {
          return _c("UserIcon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.highlightedUser || userId === _vm.highlightedUser,
                expression: "!highlightedUser || userId === highlightedUser",
              },
            ],
            key: userId,
            staticClass: "room__user",
            style: _vm.getUserPosition(index),
            attrs: { userId: userId, userStatus: status },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }