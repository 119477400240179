var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.rooms.length,
          expression: "rooms.length",
        },
      ],
      staticClass: "rooms",
      on: { click: _vm.containerClicked },
    },
    [
      _c("SearchBar", {
        staticClass: "rooms-search-bar",
        attrs: { hollow: true },
      }),
      _vm.getCurrentSpacePageData.id || _vm.getLoadingSpace
        ? _c("div", { staticClass: "rooms-overlay" })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "rooms-container" },
        [
          _vm.areaLoaded && _vm.rooms[0] && _vm.rooms[0].position
            ? _c(
                "transition-group",
                _vm._l(_vm.rooms, function (r, i) {
                  return _c("Room", { key: r.id, attrs: { data: r, index: i } })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }