<template>
    <div class="create-room-form">
      <modal-header>
        Create Room
      </modal-header>

      <form @submit.prevent="openRoom">
        <div class="form-group">
          <TextInput ref="roomNameInput" inset large label="Name your room" placeholder="Enter your room name" v-model="name"/>
          <p class="input__error" v-if="error.name">{{error.name}}</p>
        </div>

        <div class="create__type" v-if="userType >= 1 && !customizeScreenOpen">
          <div class="form__title">Select type of the Room</div>
          <div class="create__select">
            <div
              class="select__type tooltip"
              :class="{selected: picked == key, disabled: btnDisabled(key), 'select__type--full': btnFull(key)}"
              v-for="([name], key) in roomTypes"
              :key="key"
              @click="btnDisabled(key) ? null : picked = key"
            >
              {{name}}
              <span v-if="btnDisabled(key)" class="tooltiptext">Livestreaming is a premium feature, please contact support@spacein.co.uk to discuss options available</span>
            </div>
          </div>
        </div>

        <div v-if="linkType" class="form-group">
          <TextInput :label="linkType" inset placeholder="Enter Room URL" v-model="url"/>
          <p class="input__error" v-if="error.url">{{error.url}}</p>
        </div>

        <CustomiseSection
          v-if="customizeScreenOpen"
          :isCreate="true"
          :startAngle="angle"
          :updateAngle="updateAngle"
          :backgroundColour="backgroundColour"
          :updateBackground="updateBackground"
          :backgroundImage="backgroundImage"
          :newImageUploaded="newImageUploaded"
          :imageRemoved="imageRemoved"
          :showName="showName"
          :updateShowName="updateShowName"
          :textColor="textColor"
          :updateTextColour="updateTextColour"
        />
      </form>

      <FooterButtons>
        <btn @onClick="toggleCustomizeScreen" v-if="canBeCustomized(picked)" colour="ghost"> <template #text>{{ customizeScreenOpen ? "Cancel":"Customise"}}</template></btn>
        <btn @onClick="openRoom" :disabled="!isValid" colour="green"> <template #text>Open Room</template></btn>
      </FooterButtons>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalHeader from '@/framework/modals/components/newModalHeader.vue'
import { TextInput, FooterButtons } from "@/framework"
import FormChangeWatcherMixin from "@/plugins/mixins/formChangeWatcher.js";
import { convertFromServerColourScheme } from "@/functions/colourSchemes.js";
import CustomiseSection from './CustomiseSection.vue'

export default {
  name: 'CreateRoomModal',
  components: { ModalHeader, TextInput, FooterButtons, CustomiseSection },
  mixins: [ FormChangeWatcherMixin ],
  data: function() {
    const colourScheme = convertFromServerColourScheme(this.$store.getters.getCurrentAreaColourScheme)
    return {
      name: '',
      url: '',
      error: {
        name: '',
        url: ''
      },
      customizeScreenOpen: false,
      picked: 'temp',
      textColor: 'white',
      showName: true,
      angle: -1,
      // the first number represents temp or fixed, 1 is temp, 0 is fixed (lifestyle)
      // the second number is the conferenceType:
      // Spacein = 1,    // Normal spacein video call
      // Link = 2,       // Link opening in a new tab
      // Internal = 4,   // IFrame content
      // Livestream = 5, // Livestream
      // 4th argument is default colour
      // 5th argument is whether this room type can be customized
      roomTypes: {
        temp: ['Temporary Meeting Room', 1, 1, colourScheme.temporary, true],
        fixed: ['Fixed Meeting Room', 0, 1, colourScheme.fixed, true],
        livestream: ['Livestream', 0, 5, colourScheme.livestream, true],
        embed: ['Embedded Link', 0, 4, colourScheme.link, true],
        extern: ['External Link', 0, 2, colourScheme.link, true],
      },
      backgroundColour: colourScheme.fixed,
      backgroundImage: "",
      // to be indexed by conferenceType
      linkTypes: {
        2: "URL (Link will open in a new tab)",
        4: "URL (Link must support Iframe)",
        5: "HLS m3u8 URL"
      },

      formChangeWatcherSettings: {
        // This tells FormChangeWatcherMixin to watch these inputs
        // so we know when to present the "Save" button
        inputs: [
          "backgroundColour",
          "backgroundImage",
          "picked",
          "name",
          "url",
          "textColor",
          "showName"
        ],

        promptOnLeave: false
      },
    }
  },
  watch: {
    name(){
      this.error.name = !this.name ? "Room name is required" : "";
    },
    url(){
      this.error.url = !this.url && this.linkType ? "Room URL is required" : "";
    },
    linkType() {
      if(!this.linkType) {
        this.error.url = "";
        this.url = "";
      }
    },
    picked: {
      handler(newVal) {
        // when the room type has changed,
        // If the user hasn't changed the backgroundColour then set it to the default colour for that room type
        if (!this.isInputDirty('backgroundColour')) {

          // we prevent the formChangeWatcher from seeing the backgroundColour change here
          // because it would include it in the dirtiedInputs otherwise
          this.ignoreChanges(() => {
              const [, , , backgroundColour] = this.roomTypes[newVal]
              this.backgroundColour = backgroundColour
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'current_user/data',
      userType: 'current_user/currentAreaPermissionNo',
      notInRoom: 'getIfCurrentUsersRoomIsHub',
      areaData: 'getCurrentAreaData'
    }),
    livestreamDisabled: vm => !vm.areaData.features.premiumFeature,
    roomType: vm => vm.roomTypes[vm.picked],
    linkType(){
      let [,,conferenceType] = this.roomType;
      let rtn = this.linkTypes[conferenceType];
      return rtn;
    },
    isValid() {
      if (!this.name) return false
      if (this.linkType && !this.url) return false
      return true
    },
  },
  methods: {
    ...mapActions([
      'createSpaceHandler',
      'modal/close',
      'showConfirmCreateRoomWhenInMeetingPopup',
      'analytics/createRoomCancelled',
			'analytics/createRoomClick'
    ]),
    // todo: figure out how to apply analytics here
    createRoom(){
      this['modal/close']();

      let [, lifestyle, conferenceType] = this.roomType;

      let createSpaceArgs = [this.name, this.url, lifestyle, conferenceType]

      if (this.canBeCustomized(this.picked)) {
        createSpaceArgs = [...createSpaceArgs, this.backgroundColour, this.backgroundImage, this.textColor, !this.showName, this.angle]
      }
      this.createSpaceHandler(createSpaceArgs);
    },
    openRoom() {
      let e = this.error;
      if(e.name || e.url) return;

      if(!this.notInRoom) this.showConfirmCreateRoomWhenInMeetingPopup(this.createRoom);
      else this.createRoom();
    },
    btnDisabled(key){
      return key === 'livestream' && this.livestreamDisabled;
    },
    btnFull(key) {
      return key == 'temp'
    },
    canBeCustomized(key) {
      const [, , , , canBeCustomized] = this.roomTypes[key]
      return canBeCustomized
    },
    openCustomizeScreen() {
      this.customizeScreenOpen = true
    },
    closeCustomizeScreen() {
      this.customizeScreenOpen = false
    },
    toggleCustomizeScreen() {
      this.customizeScreenOpen = !this.customizeScreenOpen
    },
    //customise handlers
    newImageUploaded(url) {
      this.backgroundImage = url
    },
    imageRemoved() {
      this.backgroundImage = ""
    },
    updateBackground(val) {
      this.backgroundColour = val
    },
    updateShowName(val) {
      this.showName = val
    },
    updateTextColour(val) {
      this.textColor = val
    },
    updateAngle(a) {
      this.angle = a.value
    }
  },
  created() {
    this['analytics/createRoomClick']();
    this.name = (this.user.firstName + ' ' + this.user.lastName).trim()+"'s Room";
  }
}
</script>

<style scoped lang="scss">
  @import "~@/scss/components/modals/modal_forms.scss";
  @import '~@/scss/components/modals/create_room_form.scss';
</style>
