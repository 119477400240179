var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-room-form" },
    [
      _c("modal-header", [_vm._v(" Create Room ")]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.openRoom.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("TextInput", {
                ref: "roomNameInput",
                attrs: {
                  inset: "",
                  large: "",
                  label: "Name your room",
                  placeholder: "Enter your room name",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _vm.error.name
                ? _c("p", { staticClass: "input__error" }, [
                    _vm._v(_vm._s(_vm.error.name)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.userType >= 1 && !_vm.customizeScreenOpen
            ? _c("div", { staticClass: "create__type" }, [
                _c("div", { staticClass: "form__title" }, [
                  _vm._v("Select type of the Room"),
                ]),
                _c(
                  "div",
                  { staticClass: "create__select" },
                  _vm._l(_vm.roomTypes, function ([name], key) {
                    return _c(
                      "div",
                      {
                        key: key,
                        staticClass: "select__type tooltip",
                        class: {
                          selected: _vm.picked == key,
                          disabled: _vm.btnDisabled(key),
                          "select__type--full": _vm.btnFull(key),
                        },
                        on: {
                          click: function ($event) {
                            _vm.btnDisabled(key) ? null : (_vm.picked = key)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(name) + " "),
                        _vm.btnDisabled(key)
                          ? _c("span", { staticClass: "tooltiptext" }, [
                              _vm._v(
                                "Livestreaming is a premium feature, please contact support@spacein.co.uk to discuss options available"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.linkType
            ? _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("TextInput", {
                    attrs: {
                      label: _vm.linkType,
                      inset: "",
                      placeholder: "Enter Room URL",
                    },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                  _vm.error.url
                    ? _c("p", { staticClass: "input__error" }, [
                        _vm._v(_vm._s(_vm.error.url)),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.customizeScreenOpen
            ? _c("CustomiseSection", {
                attrs: {
                  isCreate: true,
                  startAngle: _vm.angle,
                  updateAngle: _vm.updateAngle,
                  backgroundColour: _vm.backgroundColour,
                  updateBackground: _vm.updateBackground,
                  backgroundImage: _vm.backgroundImage,
                  newImageUploaded: _vm.newImageUploaded,
                  imageRemoved: _vm.imageRemoved,
                  showName: _vm.showName,
                  updateShowName: _vm.updateShowName,
                  textColor: _vm.textColor,
                  updateTextColour: _vm.updateTextColour,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "FooterButtons",
        [
          _vm.canBeCustomized(_vm.picked)
            ? _c("btn", {
                attrs: { colour: "ghost" },
                on: { onClick: _vm.toggleCustomizeScreen },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function () {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.customizeScreenOpen ? "Cancel" : "Customise"
                            )
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4238050399
                ),
              })
            : _vm._e(),
          _c("btn", {
            attrs: { disabled: !_vm.isValid, colour: "green" },
            on: { onClick: _vm.openRoom },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [_vm._v("Open Room")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }