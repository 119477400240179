<template>
  <div class="lobby-page" data-v-step="0">
    <fade-transition>
      <!-- done as a v-if to save bandwidth for the user -->
      <div class="space-cover-image" v-if="isDesktop" v-show="bannersrc && bannerLoaded">
        <img :src="bannersrc" alt="space cover image" @load="bannerLoaded = true" />
      </div>
    </fade-transition>
    <rooms v-if="getAreaStopSpacesBackup"/>
  </div>
</template>

<script>
import { processMediaURL } from '@/functions/utility.js';
import Rooms from '@/components/space_overview/Rooms.vue';
import FadeTransition from '@/components/transitions/FadeTransition.vue';

import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Lobby',
  components: {
    Rooms,
    FadeTransition
  },
  props: {
    pageType: String
  },
  data: () => ( {
    bannerLoaded: false,
    roomLayoutTimeout: 0,
    lastLayoutRecalc: 0,
    mounted: false,
  } ),
  computed: {
    ...mapGetters([
      'getAreaStopSpacesBackup',
      'getCurrentAreaBanner',
      'loading/isFirstLobbyLoad',
      'getSpaceClicked',
      'getCurrentAreaId'
    ]),
    ...mapGetters({
      isDesktop: 'viewport/isDesktop',
      dimensions: 'viewport/dimensions'
    }),
    vm: vm => vm,
    bannersrc: vm => processMediaURL(vm.getCurrentAreaBanner),
  },
  watch: {
    isDesktop(val){
      // doing this because the sidebar transitions its width on desktop, but has to always be width 100% on mobile
      // so the positioning of the rooms gets mucked up when resizing
      if(val) setTimeout(this.recalculateRoomLayout, 200);
    },
    dimensions() {
      this.onWindowResize()
    },
    getCurrentAreaId() {
      this.initLobbyLoad()
    }
  },
  methods: {
    ...mapMutations(['loading/FIRST_LOBBY_LOAD', 'setSpaceClicked']),
    ...mapActions(['recalculateRoomLayout', 'tour/updateOnLoad', 'analytics/enterSpace', 'analytics/pendo/enterSpace']),
    onWindowResize() {
      // to be used when the new room code is usable, since it has a transition to smooth things out
      clearTimeout(this.roomLayoutTimeout);

      if((Date.now() - this.lastLayoutRecalc) < 200) this.roomLayoutTimeout = setTimeout(this.recalculateRoomLayout, 200);
      else {
        this.lastLayoutRecalc = Date.now();
        this.recalculateRoomLayout();
      }
    },
    initLobbyLoad() {
      if(this.mounted || !this.getCurrentAreaId) return
      if(this['loading/isFirstLobbyLoad']) {
        this['tour/updateOnLoad']
        this['analytics/enterSpace'](this.getSpaceClicked ? 'organic' : 'direct')
        setTimeout(() => this['analytics/pendo/enterSpace'](), 1000) //leave delay so area data goes through
        this['loading/FIRST_LOBBY_LOAD'](false)
      }
      if(this.getSpaceClicked) this.setSpaceClicked(false)
    }
  },
  mounted() {
    this.initLobbyLoad()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/scss/pages/space.scss';
</style>
